<template>
  <Block title="预算使用情况" @clickBtn="$emit('clickBtn', $event)" :actions="actions">
    <div class="c">
      <div class="item" v-for="(item, index) in showData" :key="index">
        <div class="item-t">{{item.title}}</div>
        <div class="items">
          <div class="u-item" :class="{'m-u-item': i < 2}" v-for="(v, i) in item.detail" :key="i">
            <div class="num">
              <div class="item-t">
                {{v.title}}
              </div>
              <div class="item-t" style="color: #666">
                <span class="n">{{v.num}} </span>条
              </div>
            </div>
            <Money :money="v.total"></Money>
          </div>
        </div>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from '../cmp/block.vue'
import Money from '../cmp/money.vue'

export default {
  components: {
    Block,
    Money
  },
  props: {
    data: {
      type: Object
    },
    actions: { type: Array , default: () => {
      return []
    }}
  },
  computed: {
    typeList () {
      return this.$store.getters.budgetYearTypeList
    },
    showData () {
      return [{
        title: this.typeList[0].label + '预算执行情况',
        detail: [{
          title: '未采购',
          total: this.data.normal.unusedTotal,
          num: this.data.normal.unusedCount
        },
        {
          title: '进行中',
          total: this.data.normal.usingTotal,
          num: this.data.normal.usingCount
        },
        {
          title: '已采购',
          total: this.data.normal.usedTotal,
          num: this.data.normal.usedCount
        }]
      },
      {
        title: this.typeList[1].label + '执行情况',
        detail: [{
          title: '未采购',
          total: this.data.append.unusedTotal,
          num: this.data.append.unusedCount
        },
        {
          title: '进行中',
          total: this.data.append.usingTotal,
          num: this.data.append.usingCount
        },
        {
          title: '已采购',
          total: this.data.append.usedTotal,
          num: this.data.append.usedCount
        }]
      }]
    }
  },
}
</script>

<style scoped lang="less">
.c {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  .item {
    display: block;
    width: 100%;
    .item-t {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .items {
      display: flex;
      .u-item {
        flex: 1
      }
      .m-u-item {
        margin-right: 40px;
      }
      .num {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
    .n {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00DB58;
    }
  }
}
</style>
