<template>
  <div class="page no-last-year-page" v-loading="loading < 2" v-if="last.info === null">
    <div class="left" v-if="current.info">
      <Title :title="current.year + '年预算'">
        <div class="h-t-s">
          <div></div>
          <HandleBtn :dataList="current.yearData"></HandleBtn>
        </div>
      </Title>
      <div class="top">
        <div class="t-1">
          <Total
            :data="currentTotal"
            :have-normal="current.info.haveNormal"
            :have-append="current.info.haveAppend"></Total>
        </div>
        <div class="t-2">
          <UseBlock :actions="[{key: 'detail', label: '详细'}]" @clickBtn="goDetail(current.year)" :data="currentUseData"></UseBlock>
        </div>
      </div>
      <div class="center">
        <div class="c-1">
          <PreOrg
            :have-normal="current.info.haveNormal"
            @chooseData="updateSearchParm('current', $event)"
            :have-append="current.info.haveAppend"
            :normal-data="currentCount.normal.detail || []" :append-data="currentCount.append.detail || []"></PreOrg>
        </div>
        <div class="c-2">
          <UsePie :data="currentUseTotal"></UsePie>
        </div>
      </div>
      <div class="bottom">
        <SubmitOrg
          :have-normal="current.info.haveNormal"
          @chooseData="updateSearchParm('current', $event)"
          :have-append="current.info.haveAppend"
          :normal-data="currentCount.normal.detail || []" :append-data="currentCount.append.detail || []"></SubmitOrg>
      </div>
    </div>
    <div class="left" v-else>
      <Title :title="current.year + '年预算'"></Title>
      <div class="no-img">
        <img src="/static/images/budget/wait.png" alt="">
        <div v-if="!current.isStart">预算还未开始</div>
      </div>
    </div>
    <div class="right">
      <Title :title="current.year + '年对' + last.year + '年预算的制定'"></Title>
      <div class="no-img">
        <img src="/static/images/budget/wait.png" alt="">
        <div v-if="!last.isStart">预算还未开始</div>
      </div>
    </div>
  </div>
  <div class="page have-last-year-page" v-else v-loading="loading < 2">
    <div class="left" v-if="current.info">
      <Title :title="current.year + '年预算'">
        <div class="h-t-s">
          <div></div>
          <HandleBtn :dataList="current.yearData"></HandleBtn>
        </div>
      </Title>
      <div class="top">
        <TotalAndUse
          :apply-data="currentTotal"
          :use-data="currentUseTotal"
          :actions="[{key: 'detail', label: '详细'}]"
          @clickBtn="goDetail(current.year)"></TotalAndUse>
      </div>
      <div class="center">
        <PreOrg
          :have-normal="current.info.haveNormal"
          @chooseData="updateSearchParm('current', $event)"
          :have-append="current.info.haveAppend"
          :normal-data="currentCount.normal.detail || []" :append-data="currentCount.append.detail || []"></PreOrg>
      </div>
      <div class="bottom">
        <UsePie :data="currentUseTotal"></UsePie>
      </div>
    </div>
    <div class="left" v-else>
      <Title :title="current.year + '年预算'"></Title>
      <div class="no-img">
        <img src="/static/images/budget/wait.png" alt="">
        <div v-if="!current.isStart">预算还未开始</div>
      </div>
    </div>
    <div class="right">
      <HandleBtn :data="{}" v-if="false"></HandleBtn>
      <Title :title="current.year + '年对' + last.year + '年预算的制定'">
        <div class="h-t-s">
          <div class="jxz" v-if="!last.info.isEnd">进行中</div>
          <div v-else></div>
          <HandleBtn theme="orange" :dataList="last.yearData"></HandleBtn>
          <!-- <div class="budget-btn" style="background: #FF9D6B;"><img src="/static/images/budget/qi.png" alt="">进入活动</div> -->
        </div>
      </Title>
      <div class="top">
        <div class="t-1">
          <Status :status-list="statusList" :data="lastShowDetail"></Status>
        </div>
        <div class="t-2">
          <StatusPie :status-list="statusList" :data="lastShowDetail"></StatusPie>
        </div>
      </div>
      <div class="center">
        <div class="c-1">
          <Total :data="lastTotal" theme="orange"
          :actions="[{key: 'detail', label: '详细'}]"
          @clickBtn="goDetail(last.year)"
          :have-normal="last.info.haveNormal"
          :have-append="last.info.haveAppend"
          ></Total>
        </div>
        <div class="c-2">
          <PreOrg
            :have-normal="last.info.haveNormal"
            :have-append="last.info.haveAppend"
            @chooseData="updateSearchParm('last', $event)"
            theme="orange" :normal-data="lastCount.normal.detail || []" :append-data="lastCount.append.detail || []"></PreOrg>
        </div>
      </div>
      <div class="bottom">
        <SubmitOrg theme="orange"
        @chooseData="updateSearchParm('last', $event)"
          :have-normal="last.info.haveNormal"
          :have-append="last.info.haveAppend"
          :normal-data="lastCount.normal.detail || []" :append-data="lastCount.append.detail || []"></SubmitOrg>
      </div>
    </div>
  </div>
</template>

<script>
import { budgetYearRequest, budgetDetailRequest, statusManageRequest } from '../../api'

import {
  getDetailCount,
  getBatchInfo,
  detailFilter
} from './lib'

import Title from './components/cmp/title'
import Total from './components/batch_detail/total'
import HandleBtn from './components/batch_detail/handleBtn'
import TotalAndUse from './components/batch_detail/totalAndUse'
import UseBlock from './components/batch_detail/use'
import PreOrg from './components/batch_detail/preOrg'
import UsePie from './components/batch_detail/usePie'
import SubmitOrg from './components/batch_detail/submitOrg'
import Status from './components/batch_detail/status'
import StatusPie from './components/batch_detail/statusPie'

export default {
  components: {
    Title,
    HandleBtn,
    Total,
    UseBlock,
    PreOrg,
    UsePie,
    SubmitOrg,
    TotalAndUse,
    Status,
    StatusPie,
  },
  data () {
    return {
      loading: 0,
      current: {
        year: null,
        info: null,
        detail: [],
        yearData: [],
        isStart: false,
        count: {
          normal: {},
          append: {}
        },
        searchParm: null
      },
      last: {
        year: null,
        info: null,
        detail: [],
        isStart: false,
        yearData: [],
        count: {
          normal: {},
          append: {}
        },
        searchParm: null
      },
      statusList: []
    }
  },
  created () {
    this.loadConfig()
    this.current.year = new Date().getFullYear()
    this.last.year = this.current.year + 1
    this.loadData()
  },
  computed: {
    currentShowDetail () {
      return this.current.detail.filter(v => detailFilter(v, this.current.searchParm))
    },
    currentCount () {
      return getDetailCount(this.currentShowDetail)
    },
    lastShowDetail () {
      return this.last.detail.filter(v => detailFilter(v, this.last.searchParm))
    },
    lastCount () {
      return getDetailCount(this.lastShowDetail)
    },
    budgetYearTypeList () {
      return this.$store.getters.budgetYearTypeList
    },
    currentTotal () {
      return {
        applyTotal: this.currentCount.applyTotal || 0,
        normalApplyTotal: this.currentCount.normal.applyTotal || 0,
        appendApplyTotal: this.currentCount.append.applyTotal || 0,
        preTotal: this.current.info.preTotal
      }
    },
    lastTotal () {
      return {
        applyTotal: this.lastCount.applyTotal || 0,
        normalApplyTotal: this.lastCount.normal.applyTotal || 0,
        appendApplyTotal: this.lastCount.append.applyTotal || 0,
        preTotal: this.last.info.preTotal
      }
    },
    currentUseTotal () {
      return {
        unusedTotal: this.currentCount.unusedTotal || 0,
        usedTotal: this.currentCount.usedTotal || 0,
        usingTotal: this.currentCount.usingTotal || 0
      }
    },
    currentUseData () {
      return {
        normal: this.currentCount.normal,
        append: this.currentCount.append,
      }
    }
  },
  methods: {
    updateSearchParm (type, parm) {
      if (parm && this[type].searchParm && parm.key === this[type].searchParm.key) {
        this[type].searchParm = null
      } else {
        this[type].searchParm = parm
      }
    },
    goDetail (year) {
      this.$router.push({
        name: 'internalcontrol.budget.batch_detail',
        query: {
          year
        }
      })
    },
    loadData () {
      this.loading = 0
      this.loadCurrentData()
      this.loadLastData()
    },
    async loadConfig () {
      let data = await statusManageRequest.config({
        statusGroupKey: 'budgetDetail'
      })
      this.statusList = data.statusList.filter(v => !['final_down', 'second_down', 'pre_down'].includes(v.statusKey))
    },
    async loadCurrentData () {
      let data = []
      let parm = {year: this.current.year}
      data = await budgetYearRequest.getInfo(parm)
      this.current.isStart = data.length > 0
      if (!this.$authFunsProxy.get && this.$authFunsProxy.getByAuth) {
        data = await budgetYearRequest.getMyInfo(parm)
      }
      data.forEach(item => {
        item.label = item.year + ' ' + (this.budgetYearTypeList.find(v => v.key === item.type) || this.budgetYearTypeList[1]).label + ' ' + (item.name || '')
      })
      this.current.yearData = data
      this.current.info = getBatchInfo(data)
      if (this.current.info) {
        this.current.detail = []
        if (this.$authFunsProxy.get) {
          this.current.detail = await budgetDetailRequest.getInfo(parm)
        } else if (this.$authFunsProxy.getByAuth) {
          this.current.detail = await budgetDetailRequest.getInfoByAuth(parm)
        }
      }
      this.loading += 1
    },
    async loadLastData () {
      let data = []
      let parm = {year: this.last.year}
      data = await budgetYearRequest.getInfo(parm)
      this.last.isStart = data.length > 0
      if (!this.$authFunsProxy.get && this.$authFunsProxy.getByAuth) {
        data = await budgetYearRequest.getMyInfo(parm)
      }
      data.forEach(item => {
        item.label = item.year + ' ' + (this.budgetYearTypeList.find(v => v.key === item.type) || this.budgetYearTypeList[1]).label + ' ' + (item.name || '')
      })
      this.last.yearData = data
      this.last.info = getBatchInfo(data)
      console.log(this.last.info)
      if (this.last.info) {
        this.last.detail = []
        if (this.$authFunsProxy.get) {
          this.last.detail = await budgetDetailRequest.getInfo(parm)
        } else if (this.$authFunsProxy.getByAuth) {
          this.last.detail = await budgetDetailRequest.getInfoByAuth(parm)
        }
      }
      this.loading += 1
    }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  flex: 1;
  background: #EEF3FA;
  .left {
    flex: 1255;
    height: 100%;
    padding: 0 16px 12px;
    display: flex;
    flex-direction: column;
  }
  .right {
    flex: 505;
    height: 100%;
    padding: 0 16px 12px;
    display: flex;
    flex-direction: column;
  }
  .no-img {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    img {
      width: 243px;
    }
    div {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #748295;
      margin-top: 23px;
    }
  }
  .top {
    flex: 295;
    margin-top: 14px;
    display: flex;
    margin-bottom: 13px;
    .t-1 {
      height: 100%;
      margin-right: 12px;
    }
    .t-2 {
      height: 100%;
    }
  }
  .center {
    flex: 312;
    display: flex;
    margin-bottom: 13px;
    .c-1 {
      height: 100%;
      margin-right: 12px;
    }
    .c-2 {
      height: 100%;
    }
  }
  .bottom {
    flex: 329;
  }
}
.no-last-year-page {
  .left {
    background: #F7F9FD;
    box-shadow: 0px 2px 21px 0px rgba(100,131,175,0.4);
  }
  .t-1 {
    flex: 386;
  }
  .t-2 {
    flex: 824;
  }
  .c-1 {
    flex: 798;
  }
  .c-2 {
    flex: 413;
  }
}

.have-last-year-page {
  .left {
    flex: 479;
  }
  .right {
    flex: 1249;
    background: #F7F9FD;
    box-shadow: 0px 2px 21px 0px rgba(100,131,175,0.4);
  }
  .t-1 {
    flex: 824;
  }
  .t-2 {
    flex: 384;
  }
  .c-1 {
    flex: 411;
  }
  .c-2 {
    flex: 798;
  }
}
.jxz {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
  padding: 0 5px;
  background: #FF5050;
  border-radius: 3px;
  margin-left: 12px;
}
.h-t-s {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }
  .budget-btn {
    background: #3776ED;
    border-radius: 4px;
    height: 26px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding: 0 10px;
    display: flex;
    align-items: center;
    img {
      height: 13px;
      padding-right: 5px;
    }
  }
</style>