<template>
  <div class="t-a-u">
  <Block title="预算情况" style="flex: 1;height: 100%">
    <div class="c">
      <div class="item">
        <div class="item-t">总预算</div>
        <div class="num">
          <Money :money="applyData.applyTotal"></Money>
        </div>
      </div>
      <div class="item">
        <div class="item-t">{{budgetYearTypeList[0].label}}</div>
        <div class="num">
          <Money :money="applyData.normalApplyTotal"></Money>
        </div>
      </div>
      <div class="item">
        <div class="item-t">{{budgetYearTypeList[1].label}}</div>
        <div class="num">
          <Money :money="applyData.appendApplyTotal"></Money>
        </div>
      </div>
    </div>
  </Block>
  <Block title="预算执行情况" style="flex: 1;height: 100%" @clickBtn="$emit('clickBtn', $event)" :actions="actions">
    <div class="c">
      <div class="item">
        <div class="item-t">未采购</div>
        <div class="num">
          <Money :money="useData.unusedTotal"></Money>
        </div>
      </div>
      <div class="item">
        <div class="item-t">进行中</div>
        <div class="num">
          <Money :money="useData.usingTotal"></Money>
        </div>
      </div>
      <div class="item">
        <div class="item-t">已采购</div>
        <div class="num">
          <Money :money="useData.usedTotal"></Money>
        </div>
      </div>
    </div>
  </Block>
  </div>
</template>

<script>
import Block from '../cmp/block'
import Money from '../cmp/money'

export default {
  components: {
    Block,
    Money
  },
  props: {
    applyData: {
      type: Object
    },
    useData: {
      type: Object
    },
    actions: { type: Array , default: () => {
      return []
    }}
  },
  computed: {
    budgetYearTypeList () {
      return this.$store.getters.budgetYearTypeList
    }
  }
}
</script>

<style scoped lang="less">
.t-a-u {
  display: flex;
  width: 100%;
  height: 100%;
}
.c {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  .item {
    .item-t {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .num {
      font-size: 30px;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #222222;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      position: relative;
      img {
        position: absolute;
        bottom: 5px;
        right: 0;
        height: 20px;
        width: 63px;
      }
      .dw {
        font-size: 20px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #222222;
        line-height: 30px;
      }
    }
  }
}
</style>
